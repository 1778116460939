import { useTranslation } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import { ArrowForwardIcon, Button, Grid, Message, MessageText, Modal, Text, ModalTitle, Heading } from '@pancakeswap/uikit'
import { FlexGap } from 'components/Layout/Flex'
import styled from 'styled-components'
import { ChainLogo } from 'components/Logo/ChainLogo'
import useAuth from 'hooks/useAuth'
import { useSessionChainId } from 'hooks/useSessionChainId'
import { useSwitchNetwork } from 'hooks/useSwitchNetwork'
import Image from 'next/image'
import { Chain, useAccount, useNetwork } from 'wagmi'
import Dots from '../Loader/Dots'

// Where page network is not equal to wallet network
export function WrongNetworkModal({ currentChain, onDismiss }: { currentChain: Chain; onDismiss: () => void }) {
    const { switchNetworkAsync, isLoading, canSwitch } = useSwitchNetwork()
    const { chain } = useNetwork()
    const { logout } = useAuth()
    const { isConnected } = useAccount()
    const [, setSessionChainId] = useSessionChainId()
    const chainId = currentChain.id || ChainId.BSC_TESTNET
    const { t } = useTranslation()

    const switchText = t('Đổi mạng sang %network%', { network: currentChain.name })
    function handleClick() {
        onDismiss()
        logout().then(() => {
            setSessionChainId(chainId)
        })
    }
    return (
        <CsModal title={t('Bạn đang kết nối sai network')} headerBackground="gradientCardHeader" onDismiss={handleClick}>
            <CsGrid style={{ gap: '16px' }}>
                <CsText>{t('Trang này trên  %network%.', { network: currentChain.name })}</CsText>
                <Text>
                    {t('Hiện tại bạn đang liên kết với %network%, vui lòng đổi mạng để tiếp tục.', { network: chain?.name ?? '' })}
                </Text>
                <div style={{ textAlign: 'center' }}>
                    <Image
                        layout="fixed"
                        width="215px"
                        height="215px"
                        src="/images/check_netword.png?version=1.1"
                        alt="check your network"
                    />
                </div>
                <Message variant="warning" icon={false} p="8px 12px">
                    <MessageText>
                        <FlexGap gap="12px">
                            <FlexGap gap="6px">
                                <ChainLogo chainId={chain?.id} /> <ArrowForwardIcon color="#D67E0A" />
                                <ChainLogo chainId={chainId} />
                            </FlexGap>
                            <span>{t('Đổi network để tiếp tục.')}</span>
                        </FlexGap>
                    </MessageText>
                </Message>
                {canSwitch ? (
                    <Button isLoading={isLoading} onClick={() => switchNetworkAsync(chainId)}>
                        {isLoading ? <Dots>{switchText}</Dots> : switchText}
                    </Button>
                ) : (
                    <Message variant="danger">
                        <MessageText>{t('Không thể chuyển network được. Vui lòng thử lại')}</MessageText>
                    </Message>
                )}
                {isConnected && (
                    <Button
                        onClick={() =>
                            logout().then(() => {
                                setSessionChainId(chainId)
                            })
                        }
                        variant="secondary"
                    >
                        {t('Ngắt kết nối ví')}
                    </Button>
                )}
            </CsGrid>
        </CsModal>
    )
}

const CsModal = styled(Modal)`
    ${ModalTitle}{
      @media screen and (max-width: 500px) {
        padding-top:30px;
      }
    }
    ${Heading}{
      font-weight:800;
      font-size:20px;
    }
    overflow-y:hidden;
`
const CsGrid = styled(Grid)`
  width: 400px;
  @media screen and (max-width: 600px) {
    max-width: 306px;
  }
`
const CsText = styled(Text)`
    width: 100%;
    margin-top: 12px;
    text-align:left;
    @media screen and (max-width: 600px) {
      text-align:left;
      font-size: 15px;
    }
`