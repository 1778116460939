import memoize from 'lodash/memoize'
import { ContextApi } from '@pancakeswap/localization'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
    title: "Certificate of Innovative Startup Investment Fund Go Global Franchise Fund",
    image: 'https://ccq.goglobalholdings.com/wp-content/uploads/2023/07/Chung-chi-Quy-dau-tu-khoi-nghiep-sang-tao-Go-Global.jpeg',
    description: "A program created to address the growing problem of startups and SMEs globally who want to scale quickly and sustainably through franchising with limited resources."
}

interface PathList {
  paths: { [path: string]: { title: string; basePath?: boolean; description?: string } }
  defaultTitleSuffix: string
}

const getPathList = (t: ContextApi['t']): PathList => {
  return {
    paths: {
      '/': { title: t('Trang chủ') },
      '/release': { basePath: true, title: t('Phát hành') },
      '/history': { basePath: true, title: t('Lịch sử') },
      '/edit': { basePath: true, title: t('Chỉnh sửa') },
      '/detail': { basePath: true, title: t('Chi tiết') },
    },
    defaultTitleSuffix: t('Chứng chỉ Quỹ FUNDGO'),
  }
}

export const getCustomMeta = memoize(
  (path: string, t: ContextApi['t'], _: string): PageMeta => {
    const pathList = getPathList(t)
    const pathMetadata =
      pathList.paths[path] ??
      pathList.paths[Object.entries(pathList.paths).find(([url, data]) => data.basePath && path.startsWith(url))?.[0]]

    if (pathMetadata) {
      return {
        title: `${pathMetadata.title} | ${t(pathList.defaultTitleSuffix)}`,
        ...(pathMetadata.description && { description: pathMetadata.description }),
      }
    }
    return null
  },
  (path, t, locale) => `${path}#${locale}`,
)
