import { createReducer } from '@reduxjs/toolkit'
import {
    fetchPageNumber,
    fetchStatusLoading,
    getListItems,
    getListListing,
    actionDispatchMessage,
    fetchStatisticalItems
} from './actions'
import { Item, itemListing, WrappedItems } from './type'

interface global {
    isLoading: boolean,
    listWrappedItem: WrappedItems[],
    messageHash: string,
    listItems: Item[],
    pagenumbercount: number,
    listListing: itemListing[],
}

export const initialState: global = {
    isLoading: false,
    listWrappedItem: [],
    messageHash: null,
    listItems: [],
    pagenumbercount: 0,
    listListing: [],
}

export default createReducer(initialState, (builder) =>
    builder
        .addCase(fetchStatusLoading, (state, action) => {
            state.isLoading = action.payload.isLoading
        })
        .addCase(fetchStatisticalItems, (state, action) => {
            state.listWrappedItem = action.payload.listWrapped
        })
        .addCase(fetchPageNumber, (state, action) => {
            state.pagenumbercount = action.payload.pagenumbercount
        })
        .addCase(getListItems, (state, action) => {
            state.listItems = action.payload?.listItems
        })
        .addCase(getListListing, (state, action) => {
            state.listListing = action.payload.listListing
        })
        .addCase(actionDispatchMessage, (state, action) => {
            state.messageHash = action.payload.messageHash;
        })
)