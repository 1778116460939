import { createReducer } from '@reduxjs/toolkit'
import { getContractAddress, getDataContractAddress, actionAccountFundMember } from './actions'
import { DataContractAddress } from './type'

interface global {
    contractAddress: string,
    dataContractAddress: DataContractAddress[],
    addressFundMember: DataContractAddress[],
    accountFundMember: string,
}
export const initialState: global = {
    contractAddress: "",
    dataContractAddress: [],
    addressFundMember: [],
    accountFundMember: ""
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(getContractAddress, (state, action) => {
            state.contractAddress = action.payload.contractAddress
        })
        .addCase(getDataContractAddress, (state, action) => {
            state.dataContractAddress = action.payload
        })
        .addCase(actionAccountFundMember, (state, action) => {
            const { dataContractAddress } = state;
            state.accountFundMember = action.payload;
            const addressFundMember = dataContractAddress.filter((item: DataContractAddress) => item?.receivedAddress?.toLowerCase() === action.payload?.toLowerCase());
            state.addressFundMember = addressFundMember;
        })
)