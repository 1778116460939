import { useTranslation } from '@pancakeswap/localization'
import { ChainId } from '@pancakeswap/sdk'
import { Box, Button, Grid, Heading, Message, MessageText, Modal, ModalTitle, Text } from '@pancakeswap/uikit'
import { useMenuItems } from 'components/Menu/hooks/useMenuItems'
import { getActiveMenuItem, getActiveSubMenuItem } from 'components/Menu/utils'
import { useHistory } from 'contexts/HistoryContext'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useAuth from 'hooks/useAuth'
import { useSwitchNetwork, useSwitchNetworkLocal } from 'hooks/useSwitchNetwork'
import Image from 'next/future/image'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import styled from 'styled-components'
import { chains } from 'utils/wagmi'

export function PageNetworkSupportModal() {
    const { t } = useTranslation()
    const { switchNetworkAsync, isLoading, canSwitch } = useSwitchNetwork()
    const switchNetworkLocal = useSwitchNetworkLocal()
    const { chainId, isConnected, isWrongNetwork } = useActiveWeb3React()
    const { logout } = useAuth()

    const foundChain = useMemo(() => chains.find((c) => c.id === chainId), [chainId])
    const historyManager = useHistory()

    const lastValidPath = historyManager?.history?.find((h) => ['/swap', '/liquidity'].includes(h))

    const menuItems = useMenuItems()
    const { pathname, push } = useRouter()

    const { title, image } = useMemo(() => {
        const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
        const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

        return {
            title: activeSubMenuItem?.disabled ? activeSubMenuItem?.label : activeMenuItem?.label,
            image: activeSubMenuItem?.image || activeMenuItem?.image,
        }
    }, [menuItems, pathname])
    function handleClick() {
        logout().then(() => {
            push('/')
            switchNetworkLocal(ChainId.BSC_TESTNET)
        })
    }
    return (
        <CsModal title={title || t('Kiểm tra network của bạn')} onDismiss={handleClick} headerBackground="gradientCardHeader">
            <CsGrid style={{ gap: '16px' }}>
                <CsText bold>{t('Đó là tính năng chỉ dành cho BNB Smart Chain')}</CsText>
                {image && (
                    <Box mx="auto" my="8px" position="relative" width="100%" minHeight="250px">
                        <Image src={image} alt="feature" fill style={{ objectFit: 'contain' }} unoptimized />
                    </Box>
                )}
                <CsText small>
                    {t(
                        'Các tính năng   NFT của chúng tôi hiện chỉ khả dụng trên BNB Chain! Hãy đến và tham gia cộng đồng trong niềm vui!',
                    )}
                </CsText>
                {canSwitch ? (
                    <Button
                        variant={foundChain && lastValidPath ? 'secondary' : 'primary'}
                        isLoading={isLoading}
                        onClick={() => (isWrongNetwork ? switchNetworkLocal(ChainId.BSC_TESTNET) : switchNetworkAsync(ChainId.BSC_TESTNET))}
                    >
                        {t('Chuyển mạng sang %chain%', { chain: 'BNB Smart Chain' })}
                    </Button>
                ) : (
                    <Message variant="danger">
                        <MessageText>{t('Không thể chuyển đổi mạng. Hãy thử nó trên ví của bạn')}</MessageText>
                    </Message>
                )}
                {isConnected && (
                    <Button
                        variant="secondary"
                        onClick={() =>
                            logout().then(() => {
                                push('/')
                            })
                        }
                    >
                        {t('Ngắt kết nối ví')}
                    </Button>
                )}
                {foundChain && lastValidPath && (
                    <NextLink href={lastValidPath} passHref>
                        <Button as="a">{t('Ở lại %chain%', { chain: foundChain.name })}</Button>
                    </NextLink>
                )}
            </CsGrid>
        </CsModal>
    )
}

const CsModal = styled(Modal)`
    ${ModalTitle}{
      @media screen and (max-width: 500px) {
        padding-top:30px;
      }
    }
    ${Heading}{
      font-weight:800;
      font-size:22px;
    }
    overflow-y:hidden;
`
const CsGrid = styled(Grid)`
  width: 400px;
  @media screen and (max-width: 600px) {
    max-width: 316px;
  }
`
const CsText = styled(Text)`
    width: 100%;
    margin-top: 12px;
    text-align:center;
    @media screen and (max-width: 600px) {
      text-align:left;
      font-size: 15px;
    }
`