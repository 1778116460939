import { ChainId } from '@pancakeswap/sdk'
import BigNumber from 'bignumber.js'
import { DataNftItem, DataNftType, TypeNft } from 'components/TypeNFT'
import { getFullDecimalMultiplier } from 'utils/getFullDecimalMultiplier'

export const BSC_BLOCK_TIME = 3
export const BASE_BSC_SCAN_URLS = {
    [ChainId.BSC]: 'https://bscscan.com',
    [ChainId.BSC_TESTNET]: 'https://testnet.bscscan.com',
}
// CAKE_PER_BLOCK details
// 40 CAKE is minted per block
// 20 CAKE per block is sent to Burn pool (A farm just for burning cake)
// 10 CAKE per block goes to CAKE syrup pool
// 9 CAKE per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// CAKE/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const CAKE_PER_BLOCK = 40
export const BLOCKS_PER_DAY = (60 / BSC_BLOCK_TIME) * 60 * 24
export const BLOCKS_PER_YEAR = BLOCKS_PER_DAY * 365 // 10512000
export const CAKE_PER_YEAR = CAKE_PER_BLOCK * BLOCKS_PER_YEAR

export const CAKE_PER_BLOCKV2 = new BigNumber(0.07)
export const BLOCKS_PER_YEARV2 = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365) // 10512000
export const CAKE_PER_YEARV2 = CAKE_PER_BLOCKV2.times(BLOCKS_PER_YEARV2)

export const BASE_URL = 'https://board.runtogether.net'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const DEFAULT_TOKEN_DECIMAL = getFullDecimalMultiplier(18)
export const DEFAULT_GAS_LIMIT = 250000
export const BOOSTED_FARM_GAS_LIMIT = 500000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const startTimeStake = 1662469200000
export const LIMIT_VOTING = 100
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
export const AMOUNT_FULL_POOL = "2500000"
export const NUMBER_TERM = 16
export const UNITDIFF = 'days'
export const UNITDATE = 'ngày'
export const PAY_DAY_NEXT_PROFIT = "DD/MM : HH:mm";
export const CONTENT_ERROR = "Bạn đã hủy giao dịch này!";


export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[ChainId.BSC]
export const BASE_BSC_URL = BASE_BSC_SCAN_URLS[ChainId.BSC]
export const URL = process.env.NEXT_PUBLIC_API
export const TRANDING_FEE = 3
export const TRANSFER_FEE = 5
export const CHAIN_ID_SUPPORT_PAGES = [ChainId.BSC_TESTNET]


export const PAYMENT_TOKEN = {
    VND: "VND", // VNDC
    USD: "USD", // USDT
}
export const PAYMENT = PAYMENT_TOKEN.VND

export const PRICE = PAYMENT === PAYMENT_TOKEN.VND ? 100000 : 10;
export const PRICE_STRING = PAYMENT === PAYMENT_TOKEN.VND ? "100.000" : "10";
export const PRICE_WEI = PAYMENT === PAYMENT_TOKEN.VND ? PRICE : new BigNumber(PRICE).multipliedBy(1E18).toString();

export const formatNumberPayment = (price, minimum = 0, maximum = 0) => {
    if (!price) return 0;
    if (PAYMENT === PAYMENT_TOKEN.USD) {
        return Number(new BigNumber(price).decimalPlaces(5, 1).toString()).toLocaleString('en-US', { minimumFractionDigits: 5, maximumFractionDigits: 5 }).replace(/\.?0+$/, '')
    }
    return Number(new BigNumber(price).decimalPlaces(0, 1).toString()).toLocaleString('en-US', { minimumFractionDigits: minimum, maximumFractionDigits: maximum })
}

export const paymentTokenMarketplace97 = (payment) => {
    switch (payment) {
        case PAYMENT_TOKEN.VND:
            return "0xFD9A3B0348E36b14282ff0EcC4DDF35167ccBe87";
        case PAYMENT_TOKEN.USD:
            return "0x4e7B7202EDA6e0A497d5C49b8236c8a63F269cC1";
        default:
            return "";
    }
}

export const chainKey = (chainId: number) => {
    switch (chainId) {
        case ChainId.BSC:
            return "bsc_mainnet";
        case ChainId.ONUS:
            return "onus_mainnet";
        default:
            return "bsc_testnet";
    }
}

export const transferData: DataNftType[] = [
    {
        title: "Có",
        value: true,
    },
    {
        title: "Không",
        value: false,
    }
]

export const investData: DataNftType[] = [
    {
        title: "Chứng chỉ Quỹ",
        value: true,
    },
    {
        title: "Quyền đầu tư",
        value: false,
    }
]
export const mortgageData: DataNftType[] = [
    {
        title: "Không",
        value: 0,
    },
    {
        title: "Cổ phần",
        value: 1,
    },
    {
        title: "Tài sản",
        value: 2,
    }
];

export const initialStateNFT: TypeNft = {
    isInvest: {
        title: "Có",
        type: "invest",
        value: true,
    },
    isTrade: {
        title: "Có",
        type: "trade",
        value: true,
    },
    mortgageStatus: {
        title: "Không",
        type: "mortgage",
        value: 0,
    }
}

export const checkTitleType = (title: string) => {
    switch (title) {
        case "trade":
            return "Cho phép chuyển nhượng";
        case "invest":
            return "Loại Chứng chỉ Quỹ";
        case "mortgage":
            return "Hình thức thế chấp";
        default:
            return "";
    }
};

