import { createAction } from '@reduxjs/toolkit'
import { itemListing } from 'state/asset/type'
import { ListItem, WrappedItems } from './type'

export const fetchStatusLoading = createAction<{ isLoading: boolean }>('statistical/fetchStatusLoading')
export const fetchStatisticalItems = createAction<{listWrapped : WrappedItems[]}>('statistical/fetchStatisticalItems')

export const fetchPageNumber = createAction<{ pagenumbercount: number }>('statistical/fetchPageNumber')
export const actionDispatchMessage = createAction<{ messageHash: string }>('statistical/messageActions');

export const getListItems = createAction<ListItem>('statistical/getListItems')

export const getListListing = createAction<{ listListing: itemListing[] }>('statistical/getListListing')
export const getIntervestTermRate = createAction<{ interVestTermRate: number }>('statistical/getIntervestTermRate')