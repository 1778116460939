import { accountProps } from "state/auth/type";

export const dataUser: accountProps[] = [
    {
        account: '0xe2704FdA9aD61C5d316a67f3b6C44EE80ef91cC7',
        type: 1,
        name: 'Quỹ đầu tư khởi nghiệp sáng tạo FUNDGO',
        nickName: "Fundgo",
        image: 'images/logo-fundgo.png',
    },
    {
        account: '0x55F744E8715e53ED19448fdA0BEdFA6bFd4F376a',
        type: 2,
        name: 'JadeLabs Pte (Singapore)',
        nickName: "JadeLabs Pte",
        image: 'images/ccqFundgo/jadlabs.png'
    },
]